.main {
  height: calc(100vh - 64px);
  overflow: auto;
}
.header-box {
  color: #fff;
}
.user {
  float: right;
  font-size: 16px;
  cursor: pointer;
}
.logo{
  padding-left: 20px;
  box-sizing: border-box;
  width: 200px;
  background-color: #ffffff1a;
  float: left;
}
.menu-style{
  padding-top: 20px;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 16px;
}
.menu-title{
  padding: 20px 0 20px 25px;
  font-size: 12px;
  color: #999;
}
.trigger{
  padding-top: 23px;
  font-size: 18px;
  float: left;
  width: 50px;
}
