@import "~antd/dist/antd.css";

body {
  background: #f6f5f7;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  border-left: 0;
  background-image: linear-gradient(0deg, #f5f5f5, #fff);
}

::-webkit-scrollbar:vertical {
  width: 0.5rem;
}

::-webkit-scrollbar:horizontal {
  height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: lightgrey;
}
